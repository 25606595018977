@import "../../assets/scss/global.scss";

.listStyle {
  list-style: none;
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 576px) {
    -webkit-column-count: 2; /* Chrome/Opera, Safari */
    -moz-column-count: 2; /* Mozilla Firefox */
    column-count: 2;
  }
  li {
    p {
      font-family: "ubuntumedium", sans-serif;
    }
  }
}

.experienceWrapper {
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
  padding: 20px 30px 30px 30px;
  margin: 20px 5px;
}
.experienceWrapper:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.root {
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/road.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.demo {
  padding-left: 8px;
}
