body,
html {
  height: 100%;
  margin: 0 !important;
}

.topImageWrapper {
  background-image: url("../../assets/images/computer.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  position: relative;
  @media only screen and (min-width: 567px) {
    min-height: 100vh;
  }
  .headerText {
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
    font-family: "ubuntumedium", sans-serif;
    font-size: 50px;
    text-shadow: 0px 0px 30px black;
    @media only screen and (max-width: 566px) {
      top: 28%;
      font-size: 40px;
      padding: 0 10px;
    }
    span {
      font-size: 27px;
      display: block;
      padding-top: 8px;
      font-family: "ubuntumedium_italic", sans-serif;
    }
    @media only screen and (min-width: 566px) {
      animation-name: topToDown;
      animation-duration: 3s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes topToDown {
  from {
    top: 0px;
  }
  to {
    top: 40%;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    box-shadow: 0px 0px 46px -4px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 46px -4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 46px -4px rgba(0, 0, 0, 0.3);
  }
}
