@import "../../assets/scss/global.scss";

.profilePic {
  width: 100%;
  @media only screen and (min-width: 992px) {
    padding: 30px 50px 50px 50px;
  }
  border-radius: 50%;
}

.root {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 40px 0;
}
.paper {
  padding: 10px;
  text-align: "center";
  color: blue;
}

p {
  font-family: "ubunturegular";
}

.downloadFile {
  a {
    font-family: "ubuntubold", sans-serif;
    font-size: 20px;
    text-decoration: underline;
  }
}
