.colorLine {
  width: 100%;
  background-color: #fedd00;
  padding: 20px;
  margin: 14px 0 15px 0;
}

.title {
  margin: 20px;
  font-family: "ubuntubold", sans-serif;
  font-size: 40px;
}

* {
  scroll-behavior: smooth;
}
