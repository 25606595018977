.footer {
  width: 100%;
  padding: 30px 0;
  background-color: #111;
  border-top: 4px solid #fedd00;
  .footerText {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-family: "ubuntubold", sans-serif;
    span {
      display: block;
      font-family: "ubuntumedium_italic", sans-serif;
    }
  }
}

.footerImage {
  width: 40px;
  height: 40px;
}
