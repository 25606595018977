/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 19, 2021 */

@font-face {
  font-family: "ubuntubold";
  src: url("./assets/fonts/ubuntu-bold-webfont.eot");
  src: url("./assets/fonts/ubuntu-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-bold-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-bold-webfont.svg#ubuntubold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntubold_italic";
  src: url("./assets/fonts/ubuntu-bolditalic-webfont.eot");
  src: url("./assets/fonts/ubuntu-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-bolditalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-bolditalic-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-bolditalic-webfont.svg#ubuntubold_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntuitalic";
  src: url("./assets/fonts/ubuntu-italic-webfont.eot");
  src: url("./assets/fonts/ubuntu-italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-italic-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-italic-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-italic-webfont.svg#ubuntuitalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntulight";
  src: url("./assets/fonts/ubuntu-light-webfont.eot");
  src: url("./assets/fonts/ubuntu-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-light-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-light-webfont.svg#ubuntulight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntulight_italic";
  src: url("./assets/fonts/ubuntu-lightitalic-webfont.eot");
  src: url("./assets/fonts/ubuntu-lightitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-lightitalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-lightitalic-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-lightitalic-webfont.svg#ubuntulight_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntumedium";
  src: url("./assets/fonts/ubuntu-medium-webfont.eot");
  src: url("./assets/fonts/ubuntu-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-medium-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-medium-webfont.svg#ubuntumedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntumedium_italic";
  src: url("./assets/fonts/ubuntu-mediumitalic-webfont.eot");
  src: url("./assets/fonts/ubuntu-mediumitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-mediumitalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-mediumitalic-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-mediumitalic-webfont.svg#ubuntumedium_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubunturegular";
  src: url("./assets/fonts/ubuntu-regular-webfont.eot");
  src: url("./assets/fonts/ubuntu-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ubuntu-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-regular-webfont.woff") format("woff"),
    url("./assets/fonts/ubuntu-regular-webfont.svg#ubunturegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
