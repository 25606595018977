@import "../../assets/scss/global.scss";

.root {
  padding: 30px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
}
.media {
  height: 140;
}

.card {
  box-shadow: 0px 0px 46px -4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 46px -4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 46px -4px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
.card:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.record {
  padding: 20px 0;
}

p,
h5 {
  font-family: "ubunturegular";
}
