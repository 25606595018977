.navigator {
  position: fixed;
  top: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 10px 27px -17px rgba(0, 0, 0, 0.61);
  -webkit-box-shadow: 0px 10px 27px -17px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 10px 27px -17px rgba(0, 0, 0, 0.61);
  left: 0;
  right: 0;
  font-family: "ubuntubold", sans-serif;
  a {
    color: #111;
    padding: 0.5rem 0.9rem;
  }
}
